import { Button } from '@chakra-ui/react';
import {
  DataType,
  PropertyScope,
  RpgConfigProperty,
} from '@common/studio-types';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { PropertiesFormQuickAdd } from './PropertiesFormQuickAdd';

type Props = {
  onCreateProperty: (prop: RpgConfigProperty) => void;
};

type PropertyConfig = {
  icon: string;
  label: string;
  description: string;
  onCreate: () => RpgConfigProperty;
};

const properties: PropertyConfig[] = [
  {
    icon: 'number-type',
    label: 'Number',
    description:
      'You can use text properties to store character names, quest titles, or professions.',
    onCreate: () => {
      return {
        id: window.crypto.randomUUID(),
        name: '',
        scope: PropertyScope.Series,
        config: { dataType: DataType.Number, defaultValue: 0 },
      };
    },
  },
  {
    icon: 'text-type',
    label: 'Text',
    description:
      'Text properties are used to store data like character name, quest titles, or location.',
    onCreate: () => {
      return {
        id: window.crypto.randomUUID(),
        name: '',
        scope: PropertyScope.Series,
        config: { dataType: DataType.String, defaultValue: '' },
      };
    },
  },
  {
    icon: 'enum-type',
    label: 'Choice',
    description:
      'Choice properties are used to store set of data like classes, races, or item types.',
    onCreate: () => {
      const ref = window.crypto.randomUUID();

      return {
        id: ref,
        name: '',
        scope: PropertyScope.Series,
        config: {
          dataType: DataType.Enum,
          enumRef: ref,
          options: [],
        },
      };
    },
  },
];

export const PropertiesFormEmptyState: React.FC<Props> = (props) => {
  const { onCreateProperty } = props;

  return (
    <EmptyState>
      <EmptyStateText>
        <EmptyStateTitle>There are no properties yet</EmptyStateTitle>
        <EmptyStateDescription>
          Once a property is created, you can reference it in the node editor,
          see its values, and change its values across multiple episodes.
        </EmptyStateDescription>
      </EmptyStateText>
      <EmptyStateButtons>
        {properties.map((property) => (
          <EmptyStateButton key={property.label}>
            <IconContainer>
              <Icon name={property.icon} size={rawDimensions.size20} />
            </IconContainer>
            <EmptyStateButtonText>
              <EmptyStateTitle>{property.label}</EmptyStateTitle>
              <EmptyStateDescription>
                {property.description}
              </EmptyStateDescription>
            </EmptyStateButtonText>
            <Button
              size="sm"
              leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
              variant="default"
              onClick={() => onCreateProperty(property.onCreate())}
            >
              Add
            </Button>
          </EmptyStateButton>
        ))}
      </EmptyStateButtons>
      <PropertiesFormQuickAdd onCreateProperty={onCreateProperty} />
    </EmptyState>
  );
};

const EmptyState = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: ${dimensions.size24};
  padding: ${dimensions.size16};
  text-align: center;
  max-width: ${dimensions.size600};
  margin: 0 auto;
`;

const EmptyStateText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  text-align: center;
`;

const EmptyStateButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;

const EmptyStateButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${dimensions.size8};
  border-radius: ${dimensions.size8};
  background: ${({ theme }) => theme.colors.background.shade};
  padding: ${dimensions.size12};
`;

const IconContainer = styled.div`
  padding: ${dimensions.size8};
`;

const EmptyStateButtonText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  text-align: left;
`;

const EmptyStateTitle = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const EmptyStateDescription = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.body};
`;
