import { Button, ModalFooter } from '@chakra-ui/react';
import {
  DataType,
  RpgConfigHudElement,
  RpgConfigProperty,
  ValueType,
  hudIcons,
} from '@common/studio-types';
import {
  Icon,
  List,
  ListBody,
  ListHeader,
  ListHeaderColumn,
} from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import { useState } from 'react';
import styled from 'styled-components';
import { Item } from '../../../hooks/useItems';
import { HudElementForm } from './HudElementForm';
import { HudElementRow } from './HudElementRow';
import { HudElementsEmptyState } from './HudElementsEmptyState';
import { PlayerMenuInfo } from './PlayerMenuInfo';

type Props = {
  hudElements: RpgConfigHudElement[];
  onHudElementsChange: (elements: RpgConfigHudElement[]) => void;
  properties: RpgConfigProperty[];
  isLoading: boolean;
  items: Item[];
  refetchItems: () => void;
};

export const HudConfiguration: React.FC<Props> = (props) => {
  const {
    hudElements,
    properties,
    onHudElementsChange,
    refetchItems,
    isLoading,
    items,
  } = props;

  const onAddHudElement = (newElement: RpgConfigHudElement) => {
    const newElements: RpgConfigHudElement[] = [...hudElements, newElement];
    onHudElementsChange(newElements);
  };

  const [editHudElement, setEditHudElement] = useState<
    RpgConfigHudElement | undefined
  >();

  const onCreateHudElement = () => {
    const hudElement: RpgConfigHudElement = {
      id: String(Date.now()),
      displayName: '',
      value: {
        dataType: DataType.Number,
        type: ValueType.Property,
        ref: properties[0]?.id,
      },
      icon: hudIcons[0].ref,
    };
    onAddHudElement(hudElement);
    setEditHudElement(hudElement);
  };

  const onHudElementChange = (newHudElement: RpgConfigHudElement) => {
    const newHudElements = hudElements.map((item) =>
      item.id === newHudElement.id ? newHudElement : item,
    );

    onHudElementsChange(newHudElements);
  };

  const onRemoveHudElement = (hudElement: RpgConfigHudElement) => {
    const newHudElements = hudElements.filter(
      (item) => item.id !== hudElement.id,
    );
    onHudElementsChange(newHudElements);
  };

  return (
    <>
      <HudForm>
        {hudElements.length === 0 ? (
          <HudElementsEmptyState
            onCreateElement={onAddHudElement}
            properties={properties}
          />
        ) : (
          <List draggable onReorder={onHudElementsChange} records={hudElements}>
            <ListHeader draggable>
              <ListHeaderColumn width="30%">Item</ListHeaderColumn>
              <ListHeaderColumn flex>Value</ListHeaderColumn>
              <ListHeaderColumn width={dimensions.size120} />
            </ListHeader>
            <ListBody>
              {hudElements.map((hudElement) => (
                <HudElementRow
                  key={hudElement.id}
                  hudElement={hudElement}
                  onEdit={() => setEditHudElement(hudElement)}
                  onRemove={() => onRemoveHudElement(hudElement)}
                />
              ))}
            </ListBody>
          </List>
        )}
      </HudForm>
      <HudElementForm
        onItemCreated={refetchItems}
        isLoading={isLoading}
        items={items}
        properties={properties}
        hudElement={editHudElement}
        onChange={onHudElementChange}
        onRemove={() => onRemoveHudElement(editHudElement!)}
        onClose={() => setEditHudElement(undefined)}
        isOpen={!!editHudElement}
      />
      {hudElements.length < 10 && (
        <ModalFooter justifyContent="start">
          <Button
            leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
            variant="default"
            onClick={onCreateHudElement}
          >
            Add item
          </Button>
          <Divider />
          <PlayerMenuInfo />
        </ModalFooter>
      )}
    </>
  );
};

const HudForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: ${dimensions.size640};
`;

const Divider = styled.div`
  flex: 1;
`;
